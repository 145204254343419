import React, { Component } from 'react';
import './Footer.scss';
import { NavLink } from 'react-router-dom';

class Footer extends Component {
	render() {
		return (
			<div className="Footer container-fluid">
				<div className="Footer__main row">
					<div className="col">
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="Footer__heading">Snarveier</div>
									<ul className="Footer__list">
										<li className="Footer__list-item">
											<NavLink to="/produkt">Søk etter produkt</NavLink>
										</li>
										<li className="Footer__list-item">
											<NavLink to="/firma">Vareleverandør</NavLink>
										</li>
										<li className="Footer__list-item">
											<NavLink to="/om">Om VetDuAt.no</NavLink>
										</li>
										<li className="Footer__list-item">
											<NavLink to="/kontakt">Kontakt oss</NavLink>
										</li>
										<li className="Footer__list-item">
											<a href="http://tradesolution.no/personvern/" target="_blank">
												Personvern
											</a>
										</li>
									</ul>
								</div>
								<div className="col">
									<div className="Footer__heading">Kontakt Tradesolution</div>
									<ul className="Footer__list">
										<li className="Footer__list-item">+47 4000 7475</li>
										<li className="Footer__list-item">
											<a href="mailto:firmapost@tradesolution.no">firmapost@tradesolution.no</a>
										</li>
									</ul>
									<br></br>
									<div className="Footer__heading">Spørsmål til leverandør?</div>
									<ul className="Footer__list">
										<li className="Footer__list-item">
											Ta direkte kontakt med <NavLink to="/firma">vareleverandør</NavLink> for spørsmål om produkter eller andre henvendelser.
										</li>
									</ul>
								</div>
								<div className="col">
									<div className="Footer__heading">Følg oss</div>
									<ul className="Footer__list Footer__list--is-horizontal">
										<li className="Footer__list-item">
											<a href="https://www.facebook.com/tradesolution.no/" target="_blank">
												<i className="fa fa-facebook" />
											</a>
										</li>
										<li className="Footer__list-item">
											<a href="https://www.linkedin.com/company/tradesolution-as/" target="_blank">
												<i className="fa fa-linkedin" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row Footer__sub-footer">
					<div className="col">
						<div className="container">
							<div className="row">
								<div className="col">Powered by Tradesolution</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
