import React from 'react';

export class AllergenTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hierarchy: [], kanInneholde: [] };
    }

    getChildren(allergener, parentKode) {
        return allergener.filter(o => o.parentAllergenKode == parentKode);
    }

    setValues(allergen, inneholder, inneholderIkke, kanInneholde) {
        allergen.inneholder = inneholder.indexOf(allergen.allergennavn) > -1;
        allergen.inneholderIkke = inneholderIkke.indexOf(allergen.allergennavn) > -1;
        allergen.kanInneholde = kanInneholde.indexOf(allergen.allergennavn) > -1;
        allergen.ikkeOppgitt = (!allergen.inneholder && !allergen.inneholderIkke && !allergen.kanInneholde);
    }

    toHierarchy(allergener, inneholder, inneholderIkke, kanInneholde) {
        const parents = allergener.filter(a => a.parentAllergenKode == null);
        for (let parent of parents) {
            parent.expanded = false;
            parent.children = this.getChildren(allergener, parent.allergenKode);
            for (let c of parent.children) {
                this.setValues(c, inneholder, inneholderIkke, kanInneholde);
            }
        }
        for (let p of parents) {
            this.setValues(p, inneholder, inneholderIkke, kanInneholde);
        }
        return parents;
    }

    componentDidMount() {
        this.setState({ hierarchy: this.toHierarchy(this.props.allergener, this.props.inneholder, this.props.inneholderIkke, this.props.kanInneholde), kanInneholde: this.props.kanInneholde });
    }

    toggleExpanded(allergen) {
        allergen.expanded = !allergen.expanded;
        this.setState({ hierarchy: this.state.hierarchy });
    }

    render() {
        return (
            <div>
            <table className="Allergen__table">
                <thead>
                    <tr>
                        <th>Allergen</th>
                        <th>Ja</th>
                        <th>Nei</th>
                        <th>Ikke oppgitt</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.hierarchy.map(a => {
                        return (
                            <React.Fragment key={a.allergenKode}>
                                <tr key={a.allergenKode}>
                                    <td><span onClick={() => this.toggleExpanded(a)} className={a.children.length > 0 ? (a.expanded ? "expandable expanded" : "expandable") : null}>{a.allergennavn}</span></td>
                                    <td>{a.inneholder ? <i className="fa fa-circle red small" aria-hidden="true"></i> : ""}</td>
                                    <td>{a.inneholderIkke ? <i className="fa fa-circle grey small" aria-hidden="true"></i> : ""}</td>
                                    <td>{a.ikkeOppgitt ? <i className="fa fa-ban grey" aria-hidden="true"></i> : ""}</td>
                                </tr>
                                {
                                    a.expanded && a.children.map(c => {
                                        return (
                                            <tr className="childRow animated fade-in" key={c.allergenKode}>
                                                <td><span>{c.allergennavn}</span></td>
                                                <td>{c.inneholder ? <i className="fa fa-circle red small" aria-hidden="true"></i> : ""}</td>
                                                <td>{c.inneholderIkke ? <i className="fa fa-circle grey small" aria-hidden="true"></i> : ""}</td>
                                                <td>{c.ikkeOppgitt ? <i className="fa fa-ban grey" aria-hidden="true"></i> : ""}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
                </table>
                {this.state.kanInneholde && this.state.kanInneholde.length > 0 &&
                    <div className="c_kan-inneholde">
                        <div className="header">Kan inneholde spor av:</div>
                        {this.state.kanInneholde.join(", ")}
                    </div>
                }
                </div>
        );
    }
};