import React from 'react';
import { connect } from 'react-redux';
import { filterGroup, filterSingle, filterRemove, filterRemoveAll, expandFacetList } from '../../redux/searchfilter';
import { expandFacetCount } from '../../redux/search';

import Button from '../Utility/Button/Button';

class ProductSearchFilter extends React.Component {
    constructor(props) {
        super(props);

        this.onClickFilter = this.onClickFilter.bind(this);
        this.removeAllFilters = this.removeAllFilters.bind(this);
        this.isFacetFiltered = this.isFacetFiltered.bind(this);
        this.isFiltered = this.isFiltered.bind(this);
        this.renderFacetList = this.renderFacetList.bind(this);
        this.renderFacet = this.renderFacet.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    onClickFilter(isGroup, type, facet) {
        isGroup ? this.props.filterGroup(type, facet) : this.props.filterSingle(type, facet);
    }

    removeFilter(category, value) {
        this.props.filterRemove(category, value);
    }

    removeAllFilters() {
        this.props.filterRemoveAll();
    }

    expandList(type, value) {
        if (value === 2) {
            this.props.expandFacet(type);
        }
        this.props.expandList(type, value);
    }

    isFacetFiltered(group, facet) {
        return this.props.filters.filter(obj => obj.category === group && obj.value === facet.value).length > 0;
    }
    isFiltered() {
        return this.props.filters.length > 0;
    }

    renderFacet(type, title, facet, group = false) {
        if (this.props.expand[type] === 0) {
            return (
                facet.list.length > 0 &&
                <div className="ProductSearchFilter__category">
                    <div className="ProductSearchFilter__category-heading" onClick={this.expandList.bind(this, type, 1)}>{title}</div>
                </div>
            );
        }
        else {
            const isBoolFacet = type === "ErStorhusholdningsprodukt";
            return (
                <div className="ProductSearchFilter__category ProductSearchFilter__category--is-open">
                    <div className="ProductSearchFilter__category-heading" onClick={this.expandList.bind(this, type, 0)}>{title}</div>
                    {isBoolFacet ? this.renderBoolFacets(type, facet, "Servering", "Dagligvare", group) : this.renderFacetList(type, facet, group)}
                </div>
            );
        }
    }

    renderBoolFacets(type, facet, trueTitle, falseTitle, group = false) {
        return (
            <ul className="ProductSearchFilter__category-list">
                {facet.list.map((f) => {
                    if (!f.value) return false;
                    const isFaceFiltered = this.isFacetFiltered(type, f);
                    const facetClass = isFaceFiltered ? "ProductSearchFilter__category-list-item ProductSearchFilter__category-list-item--is-selected" : "ProductSearchFilter__category-list-item";
                    return (
                        isFaceFiltered ?
                            <li onClick={() => { this.removeFilter(type, f.value) }} key={f.category + "-" + f.value } className={facetClass}>
                                <span>{(f.value.toLowerCase() === "true" ? trueTitle : falseTitle)} {"(" + f.count + ")"}</span>
                            </li> :
                            <li onClick={() => { this.onClickFilter(group, type, f) }} key={f.category + "-" + f.value } className={facetClass}>
                                <span>{(f.value.toLowerCase() === "true" ? trueTitle : falseTitle)} {"(" + f.count + ")"}</span>
                            </li>
                    )
                })}
            </ul>
        );
    }

    renderFacetList(type, facet, group = false) {
        const expand = <div className="ProductSearchFilter__category-show-more" onClick={this.expandList.bind(this, type, 2)}>Vis flere</div>;

        return (
            <ul className="ProductSearchFilter__category-list">
                {facet.list.map((f) => {
                    if(!f.value) return false;
                    const isFaceFiltered = this.isFacetFiltered(type, f);
                    const facetClass = isFaceFiltered ? "ProductSearchFilter__category-list-item ProductSearchFilter__category-list-item--is-selected" : "ProductSearchFilter__category-list-item";
                    return (
                        isFaceFiltered ?
                            <li onClick={()=> {this.removeFilter(type, f.value)}} key={f.category + "-" + f.value} className={facetClass}>
                                <span>{f.value} {"(" + f.count + ")"}</span>
                            </li> :
                            <li onClick={() => {this.onClickFilter(group, type, f)}} key={f.category + "-" + f.value} className={facetClass}>
                                <span>{f.value} {"(" + f.count + ")"}</span>
                            </li>
                    )
                })}
                {facet.list.length > 1 && facet.hasMore ? expand: ''}
            </ul>
        );
    }

    render() {

        return (
            <div className={this.props.toggle ? 'ProductSearchFilter col-2' : 'ProductSearchFilter ProductSearchFilter--is-open col-2'}>
                <div className="ProductSearchFilter__close" onClick={this.props.handleToggle}>
                    <i className="fa fa-times" />
                </div>

                {this.renderFacet("AllergenerInneholder", "Inneholder", this.props.facets.AllergenerInneholder, true)}

                {this.renderFacet("AllergenerInneholderIkke", "Inneholder ikke", this.props.facets.AllergenerInneholderIkke, true)}

                {this.renderFacet("AllergenerKanInneholde", "Kan inneholde", this.props.facets.AllergenerKanInneholde, true)}

                {this.renderFacet("KategoriNavn", "Kategori", this.props.facets.KategoriNavn)}

                {this.renderFacet("Varegruppenavn", "Varegruppenavn", this.props.facets.Varegruppenavn)}

                {this.renderFacet("Produksjonsland", "Produksjonsland", this.props.facets.Produksjonsland)}

                {this.renderFacet("MerkeOrdninger", "Merkeordning", this.props.facets.MerkeOrdninger, true)}

                {this.renderFacet("ErStorhusholdningsprodukt", "Produkt type", this.props.facets.ErStorhusholdningsprodukt)}

                    <div className="ProductSearchFilter__reset">
                        <Button text="Nullstill filter" fullwidth={true} rounded={false} caps={true} onClick={this.removeAllFilters} />
                    </div>

            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        filters: state.SearchFilterReducer.filters,
        expand: state.SearchFilterReducer.facetExpand
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        filterGroup: (group, facet) => { dispatch(filterGroup(group, facet)) },
        filterSingle: (group, facet) => { dispatch(filterSingle(group, facet)) },
        filterRemove: (category, value) => { dispatch(filterRemove(category, value)) },
        filterRemoveAll: () => { dispatch(filterRemoveAll()) },
        expandList: (type, value) => { dispatch(expandFacetList(type, value)) },
        expandFacet: (type) => { dispatch(expandFacetCount(type))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchFilter)