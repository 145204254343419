import React from 'react';

class AboutComponent extends React.Component {
	componentDidMount() {
		document.title = 'Om oss - VetDuAt';
	}

	render() {
		return (
			<div className="About container">
				<div className="row">
					<div className="col">
						<h1>Om VetDuAt.no</h1>
						<h2>En bransjeportal for dine merkevarer!</h2>
						<p>
							VetDuAt.no skal være en kilde, veiviser og døråpner til dine merkevarer! Vi utnytter etablerte
							bransjeløsninger, og knytter produktinformasjon, med høy kvalitet og aktualitet, sammen med annet
							markedsmateriell. På den måten når vareleverandører nå ut til aktuelle kunder og andre aktører – enkelt,
							smart og effektivt!
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default AboutComponent;
