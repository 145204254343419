// import { fetch, addTask } from 'domain-task';
import { getConfig } from '../config';

var initialState = {
    isLoading: false,
    progress: "init",
    notFound: false,
    blogMenu: null,
    recent_isLoading: false,
    recent_list: []
};

const apiUrl = getConfig().API.WebApiBaseUrl;

export function getBlogMenu() {
    return (dispatch, getstore) => {
        var task = fetch(apiUrl + "/api/recipes/categories")
            .then(data => dispatch(gotBlogMenu(data)))
            .catch(err => dispatch({ type: "GET_BLOG_MENU_FAILED" }));

        // addTask(task);
        dispatch({ type: "REQUESTING_BLOG_MENU"});
    }
}

function gotBlogMenu(data) {
    return (dispatch, getStore) => {
        var task = data.json()
            .then(data => dispatch({
                type: "GOT_BLOG_MENU",
                menu: data
            }))
            .catch(err => dispatch({ type: "GET_BLOG_MENU_FAILED" }));

        // addTask(task);
    }
}

export function getRecentBlogPosts() {
    return (dispatch, getStore) => {
        var body = {
            search: "",
            top: 5
        };

        // var task = fetch(apiUrl + "api/blog/search",
        //     {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         method: "POST",
        //         body: JSON.stringify(body)
        //     })
        //     .then(data => data.json().then(data => dispatch({ type: "GOT_RECENT_BLOGPOSTS", data: data.value })))
        //     .catch(err => dispatch({ type: "GET_RECENT_BLOGPOSTS_FAILED" }));

        // addTask(task);
        dispatch({ type: "REQUESTING_RECENT_BLOGPOSTS" });
    }
}

export function getWordpressBlogpost(id) {
    return (dispatch, getStore) => {
        var body = {
            "id": id
        };
        let task = fetch(apiUrl + "api/blog/get",
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then(data => data.json().then(data => dispatch(getWordpressPageAction(data))))
            .catch(error => dispatch({ type: "REQUEST_WORDPRESS_BLOG_PAGE_FAILED" }));

        // addTask(task);
        dispatch({ type: "REQUESTING_WORDPRESS_BLOG_PAGE" });
    }
}


function getWordpressPageAction(page) {
    return {
        type: "GET_WORDPRESS_BLOG_PAGE",
        page: page
    }
}

export const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUESTING_BLOG_MENU":
            return Object.assign({}, state, {
                blogMenu: null,
                isLoading: true,
                notFound: false,
                progress: "requesting"
            });
        case "GOT_BLOG_MENU":
            return Object.assign({}, state, {
                blogMenu: action.menu,
                isLoading: false,
                notFound: false,
                progress: "got data"
            });
        case "GET_BLOG_MENU_FAILED":
            return Object.assign({}, state, {
                blogMenu: null,
                isLoading: false,
                notFound: true,
                progress: "failed"
            });
        case "REQUESTING_RECENT_BLOGPOSTS":
            return Object.assign({}, state, {
                recent_isLoading: true,
                recent_list: []
            });
        case "GET_RECENT_BLOGPOSTS_FAILED":
            return Object.assign({}, state, {
                recent_isLoading: false,
                recent_list: []
            });
        case "GOT_RECENT_BLOGPOSTS":
            return Object.assign({}, state, {
                recent_isLoading: false,
                recent_list: action.data
            });
        case "REQUESTING_WORDPRESS_BLOG_PAGE":
            return Object.assign({}, state, {
                page_isLoading: true,
                page_notFound: false
            });
        case "GET_WORDPRESS_BLOG_PAGE":
            return Object.assign({}, state, {
                page: action.page,
                page_isLoading: false,
                page_notFound: false
            });
        case "REQUEST_WORDPRESS_BLOG_PAGE_FAILED":
            return Object.assign({}, state, {
                page: null,
                page_isLoading: false,
                page_notFound: true
            });
        default:
            return state;
    }
}