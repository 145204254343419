// import { fetch, addTask } from 'domain-task';
import { getConfig } from '../config';

const apiUrl = getConfig().API.WebApiBaseUrl;

export function fetchSuggestions(term, maxSuggestions) {
    return (dispatch, getState) => {

        var suggestionId = getState().SearchSuggestionsReducer.currentSuggestionId + 1;

        let task = fetch(apiUrl + "/api/products/suggest/" + term)
            .then(data => data.json().then(data => dispatch({ type: "SUGGESTIONS_LOADED", suggestions: data.slice(0, maxSuggestions), suggestionId: suggestionId })))
            .catch(error => dispatch({ type: "SUGGESTIONS_FAILED" }))
            
        //// addTask(task);
        dispatch({ type: 'SEARCHING_SUGGESTIONS', suggestionId: suggestionId});
    }
}

export const SearchSuggestionsReducer = (state = { suggestions: [], loadedSuggestions: false, lookingForSuggestions: false, currentSuggestionId: 0 }, action) => {
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return Object.assign({}, state, { suggestions: [], loadedSuggestions: false });
        case "SEARCHING_SUGGESTIONS":
            return Object.assign({}, state, { lookingForSuggestions:true, currentSuggestionId: action.suggestionId });
        case "SUGGESTIONS_LOADED":
            if (state.lookingForSuggestions && action.suggestionId == state.currentSuggestionId) {
                return Object.assign({}, state, {
                    suggestions: action.suggestions.slice(0, 5),
                    loadedSuggestions: true,
                    lookingForSuggestions: false
                });
            }
            else {
                return state;
            }
        case "SUGGESTIONS_FAILED":
            return Object.assign({}, state, {
                suggestions: [],
                loadedSuggestions: true
            });
        default:
            return state;
    }
}