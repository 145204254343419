import React, { Component } from 'react';
import './Navigation.scss';
import {NavLink} from 'react-router-dom';

class Navigation extends Component {
	render() {
		return (
			<div className="col">
                <div className={this.props.toggle ? 'Navigation' : 'Navigation Navigation--is-open'}>
                    <NavLink to="/produkt" className="Navigation__item" activeClassName="Navigation__item--is-active">
                        Søk produkt
                    </NavLink>
                    <NavLink to="/firma" className="Navigation__item" activeClassName="Navigation__item--is-active">
                        Vareleverandør
                    </NavLink>
                    <NavLink to="/om" className="Navigation__item" activeClassName="Navigation__item--is-active">
                        Om VetDuAt.no
                    </NavLink>
                    {/* <NavLink to="/kontakt" className="Navigation__item" activeClassName="Navigation__item--is-active">
                        Kontakt oss
                    </NavLink> */}
                </div>
				{!this.props.toggle && <div className="Navigation__background" onClick={() => {this.props.toggleAction(true)}} />}
			</div>
		);
	}
}

export default Navigation;
