import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import SEOLink from "../Utility/SEOLink/SEOLink";
import { merkeOrdningImage } from "../Product/MerkeOrdningImage";
import { getConfig } from '../../config';
import './ProductListItem.scss';

const apiUrl = getConfig().API.WebApiBaseUrl;

class ProductListItem extends React.Component {
    constructor(props) {
        super(props);

        this.saveListNumber = this.saveListNumber.bind(this);
        this.loadImage = this.loadImage.bind(this);
    }

    saveListNumber() {
        this.props.setNumber(this.props.number);
    }

    loadImage(){
        findDOMNode(this).querySelector('.Spinner__inner').remove();
    }

    renderProductImage(product) {
        if (product.erTobakk) {
            return <img className="ProductListItem__image centered-product-image" src="/images/tobakk.jpg" onLoad={() => { this.loadImage() }} alt={product.fellesProduktnavn} />;
        }
        if (product.erSnus) {
            return <img className="ProductListItem__image centered-product-image" src="/images/snus.jpg" onLoad={() => { this.loadImage() }} alt={product.fellesProduktnavn} />;
        }
        else if (product.harBilde) {
            return <img className="ProductListItem__image centered-product-image lazyload lazyimage" onLoad={() => { this.loadImage() }} data-source={product.gtin} data-storhusholdning={product.erStorhusholdningsprodukt} data-gln={product.gln} alt={product.fellesProduktnavn} />;
        }
        return <img className="ProductListItem__image centered-product-image" src={apiUrl + "/api/Images/Medium/unknown"} onLoad={() => { this.loadImage() }} alt={product.fellesProduktnavn} />;
    }

    render() {
        return (
            <SEOLink base="/produkt/" id={'Produkt__' + this.props.product.variantIdentityGuid} linkid={this.props.product.variantIdentityGuid} onClick={this.saveListNumber} className="ProductListItem">
                <picture className="product-image">
                    <div className="Spinner__inner" />
                    {this.renderProductImage(this.props.product)}
                </picture>
                {this.props.product.firmaNavn ? <div className="ProductListItem__supplier">{this.props.product.firmaNavn}</div> : ''}
                <div className="ProductListItem__title">{this.props.product.fellesProduktnavn}</div>
                {this.props.product.mengde ? <div className="ProductListItem__quantity">{this.props.product.mengde} {this.props.product.mengdetypeenhet}</div> : ''}
                {
                    this.props.product.merkeordninger.length > 0 || this.props.product.isNewProduct ?
                    <div className="ProductListItem__icons">
                        { this.props.product.merkeordningBildeUrlList.map((moUrl, idx) => 
                            <span key={"merkeordning_" + idx}> {merkeOrdningImage(moUrl, "ProductListItem__icon")}</span>
                        )}
                        {this.props.product.isNewProduct ? <img className="ProductListItem__icon" src="/images/merkeordninger/ny.png" alt="" /> : "" }
                    </div> : ''
                }
            </SEOLink>
        );
        
    }
}

function mapStateToProps(state, ownProps) {
    return {
        //product: state.ProductReducer.product
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setNumber: (id) => { dispatch({ type:"SET_LIST_NUMBER", number: id}) }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem)
