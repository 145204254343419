import { combineReducers } from 'redux';
import { ProductReducer } from './product';
import { SearchReducer } from './search';
import { SearchSuggestionsReducer } from './searchsuggestions';
import { SearchFilterReducer } from './searchfilter';
import { RecipeSearchReducer } from './recipesearch';
import { BlogReducer } from './blog';
import { CompanyReducer } from './company';

//export const reducers = ProductReducer;
export const reducers = combineReducers({
    SearchReducer,
    ProductReducer,
    SearchSuggestionsReducer,
    SearchFilterReducer,
    RecipeSearchReducer,
    BlogReducer,
    CompanyReducer
});

export default reducers;