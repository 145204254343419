import React from 'react';
import RecentBlogPostsComponent from './RecentBlogPostsComponent';
import { connect } from 'react-redux';

import Article from '../Utility/Article/Article';
import SearchComponent from '../Search/SearchComponent';

import { getProductCount } from '../../redux/search';

class HomeComponent extends React.Component {
	componentWillMount() {
		if (this.props.count == undefined) {
			this.props.getCount();
		}
	}

	componentDidMount() {
		document.title = 'VetDuAt';
	}

	render() {
		return (
			<div className="Home">
				<div className="Hero">
					<div className="Hero__container container">
						{/*<div className="Hero__subtitle">Norges største produktsøk</div>*/}
						<div className="Hero__title">Søk oppdatert info blant {this.props.count} produkter</div>
						<div className="Hero__search">
							<SearchComponent page="Home" />
						</div>
					</div>
				</div>
				<div className="Home__articles">
					<Article
						type="call-to-action"
						title="På leting etter produktinformasjon?"
						content="VetDuAt.no er portalen for deg som ønsker informasjon om produkter i norsk dagligvare- og serveringsmarked. Her kan du søke etter kjente og kjære merkevarer, så vel som produktnyheter, lanseringer eller andre kriterier som for innhold, allergener eller merkeordninger."
						buttonText="Finn ut mer"
						buttonCaps={true}
						buttonLink="produkt"
					/>
				</div>
				<RecentBlogPostsComponent />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		count: state.SearchReducer.homepageCount
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getCount: () => {
			dispatch(getProductCount());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
