import React from 'react';
import SearchSuggestionList from './SearchSuggestionList';
import { connect } from 'react-redux';

import { searchAction } from '../../redux/search';
import { fetchSuggestions } from '../../redux/searchsuggestions';

import { withRouter } from 'react-router-dom';

import SearchBar from '../Utility/SearchBar/SearchBar';

const ENABLE_SUGGESTIONS = false;
let timeout;

class SearchComponent extends React.Component {
	constructor(props) {
		super(props);
		// TODO: Check if we can do something like var self = this;
		this.debounce = this.debounce.bind(this);

		this.search = this.search.bind(this);
		this.searchQuery = this.searchQuery.bind(this);
		this.suggestSearch = this.suggestSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);

		this.suggestedSearchClick = this.suggestedSearchClick.bind(this);
        this.handleSearchUpdate = this.handleSearchUpdate.bind(this);

		this.updateTitle = this.updateTitle.bind(this);
        this.keyUp = this.keyUp.bind(this);
        this.reduxSearch = this.reduxSearch.bind(this);
	}

	componentDidMount() {
		this.updateTitle();
	}

	//Search functions

	search(value) {
		this.searchQuery(value);
	}

	searchQuery(query) {
		this.reduxSearch(query);
	}

	reduxSearch(search) {
		this.props.search(search);
		this.props.history.push('/produkt');
	}

	//Suggester

	suggestedSearchClick(what) {
		this.searchQuery(what.target.innerText);
		this.props.setSearchText(what.target.innerText);
	}

	debounce(func, wait, immediate) {
		return () => {
			const context = this,
				args = arguments;
			const later = () => {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			const callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	suggestSearch() {
		let term = this.props.searchText;

		if (term.length < 3) {
			//Don't search until we have three characters.
			return;
		}
		this.props.getSuggestions(term);
		//this.props.search(term.value);
	}

	handleSearchUpdate(val) {
        this.props.setSearchText(val.target.value);
	}

	updateTitle() {
		if (this.props.searchText.length > 0 && this.props.searchText !== '*') {
			document.title = `Søkeresultat for ${this.props.searchText} - Søk - VetDuAt`;
		} else {
			document.title = 'Søk produkt - VetDuAt';
		}
	}

	//Keyup
	keyUp(e) {
		if (this.props.page === 'Home') {
			if (e.key === 'Enter') {
				this.props.clearSuggestions();
				this.search(this.props.searchText);
				this.updateTitle();
				// document.getElementById('search-term').blur();
			} else {
                this.debounce(() => {
                    if(ENABLE_SUGGESTIONS) this.suggestSearch();
                }, 500)();
			}
		} else {
			if (e.key === 'Enter') {
				// document.getElementById('search-term').blur();
			} else {
				if(this.props.searchText !== this.props.query) {
                    this.debounce(() => {
                        if(ENABLE_SUGGESTIONS) this.suggestSearch();
                        this.searchQuery(this.props.searchText);
                        this.updateTitle();
                    }, 500)();
				}
			}
		}
	}

	resetSearch(term) {
        this.props.setSearchText(term);
        if (this.props.page !== 'Home') this.reduxSearch('*');
	}

	//Render

	render() {
		return (
			<SearchBar
				handleKeyUp={this.keyUp}
				suggestions={this.props.suggestions}
				search={this.suggestedSearchClick}
				animated={this.props.animated}
				backgroundColor={this.props.backgroundColor}
				text={this.props.searchText === '*' ? '' : this.props.searchText}
				placeholder={this.props.placeholder ? this.props.placeholder : 'Søk etter produkt'}
				handleOnChange={this.handleSearchUpdate}
                resetSearch={this.resetSearch}
                showAdvancedTips="true"
			/>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		suggestions: state.SearchSuggestionsReducer.suggestions,
		loadedSuggestions: state.SearchSuggestionsReducer.loadedSuggestions,
		searchText: state.SearchReducer.searchQuery,
		query: state.SearchReducer.query
	};
}

function mapDispatchToProps(dispatch) {
	return {
		removeFilter: () => {
			dispatch({ type: 'REMOVE_FILTER' });
		},
		search: search => {
			dispatch(searchAction(search));
		},
		setSearchText: text => {
			dispatch({ type: 'SET_SEARCH_TEXT', searchQuery: text });
		},
		clearSuggestions: () => {
			dispatch({ type: 'CLEAR_SUGGESTIONS' });
		},
		getSuggestions: term => {
			dispatch(fetchSuggestions(term));
		},
		clearSearch: () => {
			dispatch({ type: 'CLEAR_SEARCH' });
		}
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchComponent));
