import React, { Component } from 'react';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

class Article extends Component {
	constructor(props) {
		super(props);

		this.determineLinkedArticle = this.determineLinkedArticle.bind(this);
	}

	determineLinkedArticle(button, preTitle) {
		if (this.props.path) {
			return (
				<div className="Article__container">
					<Link to={this.props.path}>
						{preTitle}
						<h2 className="Article__title">{this.props.title}</h2>
						{this.props.content ? <p className="Article__content">{this.props.content}</p> : ''}
						{button}
					</Link>
				</div>
			);
		} else {
			return (
				<div className="Article__container">
					{preTitle}
					<h2 className="Article__title"> {this.props.title}</h2>
					{this.props.content ? <p className="Article__content">{this.props.content}</p> : ''}
					{button}
				</div>
			);
		}
	}

	render() {
		let button = null;
		if (this.props.buttonText) {
			button = (
				<div className="Article__call-to-action">
					<Button
						text={this.props.buttonText}
						rounded={true}
						caps={this.props.buttonCaps}
						link={this.props.buttonLink}
					/>
				</div>
			);
		}

		let preTitle = null;
		if (this.props.preTitle) {
			preTitle = <h3 className="Article__pretitle">{this.props.preTitle}</h3>;
		}

		const styles = {
			backgroundImage: this.props.backgroundImage ? `url('${this.props.backgroundImage}')` : '',
			backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : null,
			color: this.props.backgroundImage || this.props.backgroundColor ? '#fff' : '#413745'
		};

		return (
			<div className={this.props.type ? 'Article Article--is-' + this.props.type : 'Article'} style={styles}>
				{this.determineLinkedArticle(button, preTitle)}
			</div>
		);
	}
}

export default Article;
