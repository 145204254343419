import React from 'react';

export const merkeOrdningImage = (moUrl, className) => {
    return (
        <img
            className={className}
            title={moUrl}
            src={moUrl}
            alt={moUrl}
        />
    );
}