import React, {Component} from 'react';
import './Button.scss';
const classNames = require('classnames');

// const Button = ({text, fullwidth, rounded, caps, onClick}) => {
//     return (
//         this.props.link ? <a href={this.props.link} className={btnGroupClasses}>{text}</a> : <button className={btnGroupClasses} onClick={this.props.onClick}>{this.props.text}</button>
//     );
//   } 

class Button extends Component {

    render() {
        const btnGroupClasses = classNames(
            'Button',
            {
                'Button--is-rounded': this.props.rounded,
                'Button--is-fullwidth': this.props.fullwidth,
                'Button--is-caps': this.props.caps
            }
        );
        return (
            this.props.link ? <a href={this.props.link} className={btnGroupClasses}>{this.props.text}</a> : <button className={btnGroupClasses} onClick={this.props.onClick}>{this.props.text}</button>
        );
    }
}
export default Button;
