import React from 'react';
import ProductInfo from './ProductInfo';
import { connect } from 'react-redux';
import { fetchProduct, fetchAllergener } from '../../redux/product';
import { loadReciepes } from '../../redux/recipesearch';
import { getCompanyInfo } from '../../redux/company';
import { filterLeverandor } from '../../redux/searchfilter';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

class ProductPage extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveArticles = this.retrieveArticles.bind(this);
        this.getCompany = this.getCompany.bind(this);
    }

    componentWillMount() {
        if (!this.props.notFound) {
            if (typeof (this.props.product) == 'undefined' || this.props.product.variantIdentityGuid != this.props.match.params.variantIdentityGuid) {

                if (this.isValidGUID(this.props.match.params.variantIdentityGuid)) {
                    this.props.getProduct(this.props.match.params.variantIdentityGuid);
                }
                else {
                    window.location.href = '/produkt';
                }
            }
            this.props.getAllergener();
            this.getCompany(this.props.product);
        }
    }

    isValidGUID(value)  {
		if (value.length > 0) {
			if ((/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(value)) {
				return true;
			}
		}
	
		return false;
	}

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.variantIdentityGuid != this.props.match.params.variantIdentityGuid) {
            this.props.getProduct(nextProps.match.params.variantIdentityGuid);
        }
        if (nextProps.product != this.props.product) {
            this.getCompany(nextProps.product);
        }
    }

    retrieveArticles() {
        if (this.props.articlevariantIdentityGuid != this.props.product.variantIdentityGuid) {
            this.props.getArticles(this.props.product.fellesProduktnavn, this.props.product);
        }
    }

    getCompany(product) {
        if (product) {
            this.props.getCompanyInformation(product.pakninger[0].companyIdentityGuid);
            this.props.makeFacetSearch(product.pakninger[0].firmaNavn, 0);
        }
    }

    render() {
        if (this.props.notFound) {
            return <h4>Fant ikke produkt med id {this.props.match.params.variantIdentityGuid}.</h4>;
        }

        return (
            <div id="productpage">
                {(!this.props.product || this.props.isLoading) ? <PageLoadSpinner /> : <ProductInfo product={this.props.product} articles={this.props.articles} articlesearch={this.props.articlesearch} company={this.props.company} allergener={this.props.allergener} />}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        product: state.ProductReducer.product,
        isLoading: state.ProductReducer.isLoading,
        notFound: state.ProductReducer.notFound,
        allergener: state.ProductReducer.allergener,
        articles: state.RecipeSearchReducer.list,
        articlesearch: state.RecipeSearchReducer.searching,
        articlevariantIdentityGuid: state.RecipeSearchReducer.variantIdentityGuid,
        company : {
            companyinfo: state.CompanyReducer.companyInfo,
            company_isLoading: state.CompanyReducer.company_isLoading,
            list: state.CompanyReducer.companyProductList,
            list_isLoading: state.CompanyReducer.companyproduct_isLoading
        }
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        getProduct: (id) => { dispatch(fetchProduct(id)) },
        getAllergener: () => { dispatch(fetchAllergener()) },
        getArticles: (query, product) => { dispatch(loadReciepes(query, product)) },
        getCompanyInformation: (companyIdentityGuid) => { dispatch(getCompanyInfo(companyIdentityGuid)) },
        makeFacetSearch: (lev, skip) => { dispatch(filterLeverandor(lev, skip)) }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)