import React from 'react';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';
export default class WordpressRender extends React.Component {
    constructor(props) {
        super(props);

        this.createMarkup = this.createMarkup.bind(this);
    }

    createMarkup() {
        var html = "<h2>" + this.props.page.title + "</h2>" + this.props.page.content;
        return { __html: html };
    }

    render() {
        return (
            <div className="wordpress-page">
                {(!this.props.page || this.props.isLoading) ? <PageLoadSpinner /> : <div dangerouslySetInnerHTML={this.createMarkup()} />}
            </div>
        );
    }
}