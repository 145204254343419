import React from 'react';
import { connect } from 'react-redux';
import { getWordpressRecipe } from '../../redux/recipesearch';
import WordpressRender from './WordpressRender';

class RecipeRender extends React.Component {

    componentWillMount() {
        if (this.props.page == undefined || this.props.page.id != this.props.match.params.pageid) {
            this.props.getPage(this.props.match.params.pageid);
        }
    }

    render() {
        return <WordpressRender page={this.props.page} isLoading={this.props.isLoading}/>; 
    }
}

function mapStateToProps(state, ownProps) {
    return {
        page: state.RecipeSearchReducer.page,
        isLoading: state.RecipeSearchReducer.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        getPage: (id) => { dispatch(getWordpressRecipe(id)) }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeRender)