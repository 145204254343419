import React from 'react';
import { connect } from 'react-redux';

import ProductList from './ProductList';
import ProductSearchFilter from '../ProductSearchFilter/ProductSearchFilter';

import { infinityScroll, searchAction } from '../../redux/search';
import { filterVaremerke } from '../../redux/searchfilter';

var infinityScrollTimeout = true;
class FacetSearch extends React.Component {
    constructor(props) {
        super(props);

        this.infinityscrollLoad = this.infinityscrollLoad.bind(this);
        this.renderCrawlerLink = this.renderCrawlerLink.bind(this);
    }

    componentWillMount() {
        //if (this.props.match.params != undefined && this.props.match.params.varemerke != undefined) {
            this.props.makeFacetSearch(this.props.match.params.varemerke);
        //}
        setTimeout(() => { infinityScrollTimeout = false; }, 3000);
    }

    //Infinityscroll
    infinityscrollLoad() {
        if (this.props.list.length < this.props.resultcount && !infinityScrollTimeout) {
            this.props.loadMoreResults(this.props.search, this.props.list.length);
        }
    }

    //Render
    renderCrawlerLink() {
        let skip = this.props.match.params.skip;
        if (skip == undefined) {
            skip = 0;
        }
        if (this.props.resultcount == undefined || skip >= this.props.resultcount) {
            return "";
        }

        return (
            <a className="crawlerskiplink" href={"/produkt/" + (parseInt(skip) + 20)} >Webcrawler link</a>
        );
    }

    render() {
        return (
            <div className="row d-flex flex-sm-row-reverse">
                <div className="col-lg-9 col-sm-8 col-12">
                    {this.props.isLoading ? <div className="spinner">Loading...</div> : ""}
                    {this.props.searchDone ? <p>Viser {this.props.list.length} av {this.props.resultcount} resultater.</p> : ""}
                    <ProductList list={this.props.list} onInfinityScroll={this.infinityscrollLoad} />
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                    {typeof (this.props.facets) != 'undefined' ? <ProductSearchFilter facets={this.props.facets} ignoreVaremerke={true}/> : ""}
                    {this.renderCrawlerLink()}
                </div>
            </div>);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        list: state.SearchReducer.list,
        facets: state.SearchReducer.facets,
        resultcount: state.SearchReducer.number,
        isLoading: state.SearchReducer.isLoading,
        searchDone: state.SearchReducer.searchDone,
        search: state.SearchReducer.query
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        loadMoreResults: (search, skip) => { dispatch(infinityScroll(search, skip)) },
        makeFacetSearch: (merke) => { dispatch(filterVaremerke( merke )) }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetSearch)


