import React from 'react';

class ContactComponent extends React.Component {
	componentDidMount() {
		document.title = 'Kontakt oss - VetDuAt';
	}

	render() {
		return (
			<div>
				<div className="container-fluid">
					<div className="row">
						<div className="Contact__map col">
							<iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2000.117258993239!2d10.8238077164874!3d59.91360127107973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416fa47048e185%3A0x25d7eeb5da67b19c!2sOle+Deviks+vei+6c%2C+0666+Oslo!5e0!3m2!1sno!2sno!4v1540987404415"
								width="600"
								height="450"
								frameBorder="0"
								allowFullScreen=""
							/>
						</div>
					</div>
				</div>
				<div className="Contact container">
					<div className="row">
						<div className="col">
							<div className="row">
								<h1>Kontakt oss</h1>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="Contact__box">
										<div className="Contact__box-header">Her finner du oss</div>
										<div className="Contact__box-bit">
											<h3 className="Contact__box-bit-header">Besøksadresse</h3>
											<p>Ole Deviks vei 6c, 0666 Oslo</p>
										</div>
                                        <div className="Contact__box-bit">
                                            <h3 className="Contact__box-bit-header">Postadresse:</h3>
                                            <p>Postboks 454 Økern, 0513 Oslo</p>
                                        </div>
									</div>
								</div>
								<div className="col-md-6">
                                    <div className="Contact__box">
                                        <div className="Contact__box-header">Kontakt oss</div>
                                        <div className="Contact__box-bit">
                                            <p><span className="Contact__box-bit-header">Telefon:</span> +47 400 07 475</p>
                                            <p><span className="Contact__box-bit-header">Telefaks:</span> +47 400 07 374</p>
                                            <p><a href="mailto:firmapost@tradesolution.no">firmapost@tradesolution.no</a></p>
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ContactComponent;
