import React, { Component } from "react";
import AdvancedSearchInfo from "../AdvancedSearchInfo/AdvancedSearchInfo";

class SearchBar extends Component {
	componentDidMount() {
		if(window.innerWidth > 800) document.getElementById('search-term').focus();
	}

	render() {
		let suggestions = null;
		if (this.props.suggestions.length > 0) {
			suggestions = (
				<ul className="SearchBar__suggestions">
					{this.props.suggestions.map((suggestion, index) => {
						return (
							<li className="SearchBar__suggestion" onClick={this.props.search} key={"suggestion-" + index}>
								<a>{suggestion.fellesProduktnavn}</a>
							</li>
						);
					})}
				</ul>
			);
		}

		return (
			<div
				className={this.props.animated ? "SearchBar SearchBar--animated col " : "SearchBar col"}
				style={{ backgroundColor: this.props.backgroundColor }}
			>
				<div className="container">
					<div className="row">
						<div className="col col-relative-fix">
							<input
                                noValidate
								className="SearchBar__input"
								type="text"
								placeholder={this.props.placeholder ? this.props.placeholder : "Søk"}
								id="search-term"
								onKeyUp={this.props.handleKeyUp}
								value={this.props.text}
								onChange={(e)=>{
									this.props.handleOnChange(e);
								}}
							/>
							<div className="SearchBar__icon fa fa-search" />
                            <div className="SearchBar__icon fa fa-times" onClick={() => {
                            	this.props.resetSearch('');
                                document.getElementById('search-term').focus();
                            }} />
							{suggestions}
                        </div>
					</div>
                </div>
                {this.props.showAdvancedTips && <div className="row">
                    <div className="col">
                        <AdvancedSearchInfo />
                    </div>
                </div>}
			</div>
		);
	}
}

export default SearchBar;
