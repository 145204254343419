import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadReciepes } from '../../redux/recipesearch';

class RecipeSearch extends React.Component {

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        if (this.props.list.length == 0) {
            this.props.getData(this.props.match.params.query == undefined ? "" : this.props.match.params.query);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.query != this.props.match.params.query) {
            this.props.getData(nextProps.match.params.query == undefined ? "" : nextProps.match.params.query);
        }
    }

    render() {
        return (
            <div>
                <h4>{this.props.match.params.query == undefined || this.props.match.params.query == "" ? this.props.match.params.query : this.props.match.params.query.toUpperCase()}</h4>
                {this.props.list.map((recipe, index) => {
                    return (
                        <Link className="wordpress-link" key={"recipe-" + index} to={"/recipe/" + recipe.id} >
                            <div className="row recipe">
                                <div className="col-md-2">
                                    <img src={recipe.featuredMedia} />
                                </div>
                                <div className="col-md-10">
                                    <strong>{recipe.title}</strong>
                                    <div dangerouslySetInnerHTML={{ __html: recipe.excerpt.split("\n")[0] }} />
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
            );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        list: state.RecipeSearchReducer.list
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        getData: (query) => { dispatch(loadReciepes(query)) }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeSearch)