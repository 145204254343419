import React, { Component } from "react";

class AdvancedSearchInfo extends Component {
    constructor() {
        super();
        this.state = { open: false };
    }
    
    toggle(e) {
        e.preventDefault();
        this.setState({ open: !this.state.open })
    }

    render() {
        return (
            <div className="AdvancedSearchInfo">
                <a href="#" onClick={(e) => this.toggle(e)}>
                    {!this.state.open && <span><i className="fa fa-magic" aria-hidden="true"></i> Avansert søk</span>}
                    {this.state.open && <span className="rounded-background">Bruk * for å søke på deler av ord! e.g. Mel*</span>}
                </a>
            </div>
        );
    }
}

export default AdvancedSearchInfo;