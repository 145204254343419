// import { fetch, addTask } from 'domain-task';
import { filterSearch, facetSearch } from './search';
import { companyFacetSearch } from './company';

export function filterGroup(group, facet) {
    return (dispatch, getState) => {
        var filters = getState().SearchFilterReducer.filters;
        filters = filters.filter(filter => filter.category !== group || filter.value !== facet.value);

        // special bool facet handling
        if (facet.value.toLowerCase() === "true" || facet.value.toLowerCase() === "false") {
            filters.push({
                category: group,
                value: facet.value,
                filter: group + "/any(f:f eq " + facet.value + ")"
            });
        } else {
            filters.push({
                category: group,
                value: facet.value,
                filter: group + "/any(f:f eq '" + facet.value + "')"
            });
        }

        dispatch(setFilter(filters));
    }
}

export function filterSingle(group, facet) {
    return (dispatch, getState) => {
        var filters = getState().SearchFilterReducer.filters;
        filters = filters.filter(filter => filter.category !== group);

        // special bool facet handling
        if (facet.value.toLowerCase() === "true" || facet.value.toLowerCase() === "false") {
            filters.push({
                category: group,
                value: facet.value,
                filter: group + " eq " + facet.value.toLowerCase()
            });
        } else {
            filters.push({
                category: group,
                value: facet.value,
                filter: group + " eq '" + facet.value + "'"
            });
        }

        dispatch(setFilter(filters));
    }
}

export function filterVaremerke(varemerke) {
    return (dispatch, getState) => {
        var filters = getState().SearchFilterReducer.filters;
        filters = filters.filter(filter => filter.category !== "varemerke");

        var filter = "varemerke eq '" + varemerke.toUpperCase() + "'";

        filters.push({
            category: "varemerke",
            value: varemerke,
            filter: filter
        });

        dispatch({ type: "SET_FILTER", filters: filters });
        dispatch(facetSearch(filter))
    }
}

export function filterLeverandor(lev, skip) {
    return (dispatch, getState) => {
        var filters = [...getState().SearchFilterReducer.filters];
        filters = filters.filter(filter => filter.category !== "firmaNavn");

        var filter = "FirmaNavn eq '" + lev + "'";

        filters.push({
            category: "firmaNavn",
            value: lev,
            filter: filter
        });

       // dispatch({ type: "SET_FILTER", filters: filters });
        dispatch(companyFacetSearch(filter, lev, skip))
    }
}

export function filterRemove(category, value) {
    return (dispatch, getState) => {
        var filters = getState().SearchFilterReducer.filters.filter(filter => filter.category !== category || filter.value !== value);

        dispatch(setFilter(filters));
    }
}

export function filterRemoveAll() {
    return (dispatch, getState) => {
        dispatch(setFilter([]));
    }
}

export function filterNewSearchRemove() {
    return (dispatch, getState) => {
        dispatch({ type: "NEW_SEARCH_FILTER_REMOVE" });
    }
}

function setFilter(filter) {
    return (dispatch, getState) => {
        dispatch({ type: "SET_FILTER", filters: filter });
        dispatch(sendFilter());
    }
}

function sendFilter() {
    return (dispatch, getState) => {
        var filters = [...getState().SearchFilterReducer.filters];

        if (filters.length == 0) {
            dispatch(filterSearch(""));
        }
        else if (filters.length == 1) {
            dispatch(filterSearch(filters[0].filter));
        }
        else {
            var filter = "";
            for (var i = 0; i < filters.length; i++) {

                filter += filters[i].filter;

                if (i < filters.length - 1) {
                    filter += " and ";
                }
            }
            dispatch(filterSearch(filter));
        }
    }
}

export function expandFacetList(type, value) {
    return (dispatch, getState) => {
        var facetExpand = Object.assign({}, getState().SearchFilterReducer.facetExpand);
        facetExpand[type] = value;
        dispatch({ type: "EXPAND_FACET_FILTER", expand: facetExpand });
    }
}

export function toggleDisplayFacets() {
    return (dispatch, getState) => {
        dispatch({type: "TOGGLE_DISPLAY", display: !getState().SearchFilterReducer.toggleDisplay})
    }
}

var initialState = {
    filters: [],
    facetExpand: {
        AllergenerInneholder: 0,
        AllergenerInneholderIkke: 0,
        AllergenerKanInneholde:0,
        FirmaNavn: 0,
        KategoriNavn: 0,
        Varemerke: 0,
        Varegruppenavn: 0,
        Produksjonsland: 0,
        MerkeOrdninger: 0,
        ErStorhusholdningsprodukt: 0,
    },
    toggleDisplay: true
};

export const SearchFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_FILTER":
            return Object.assign({}, state, {
                filters: action.filters
            });
        case "EXPAND_FACET_FILTER":
            return Object.assign({}, state, {
                facetExpand: action.expand
            });
        case "TOGGLE_DISPLAY":
            return Object.assign({}, state, {
                toggleDisplay: action.display
            });
        case "NEW_SEARCH_FILTER_REMOVE":
            return Object.assign({}, state, {
                filters: [],
                facetExpand: {
                    AllergenerInneholder: 0,
                    AllergenerInneholderIkke: 0,
                    AllergenerKanInneholde: 0,
                    FirmaNavn: 0,
                    KategoriNavn: 0,
                    Varemerke: 0,
                    Varegruppenavn: 0,
                    Produksjonsland: 0,
                    MerkeOrdninger: 0,
                    ErStorhusholdningsprodukt: 0,
                }
            });
        default:
            return state;
    }
}