import React from "react";
import { connect } from "react-redux";

import ProductList from "./ProductList";
import ProductSearchFilter from "../ProductSearchFilter/ProductSearchFilter";

import { infinityScroll, searchAction } from "../../redux/search";
import { toggleDisplayFacets } from "../../redux/searchfilter";

import SearchComponent from "../Search/SearchComponent";
import Button from "../Utility/Button/Button";
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

var infinityScrollTimeout = true;
class ProductSearch extends React.Component {
	constructor(props) {
		super(props);

		this.infinityscrollLoad = this.infinityscrollLoad.bind(this);
		this.renderCrawlerLink = this.renderCrawlerLink.bind(this);
		this.toggleFacetDisplay = this.toggleFacetDisplay.bind(this);
	}

	componentWillMount() {
		if (!this.props.isLoading && !this.props.searchDone) {
			var skip = this.props.match.params.skip != undefined ? this.props.match.params.skip : 0;
			console.log("1. FIRST FIRED SEARCH HERE....");
			this.props.makeInitialSearch(skip);
		} else if (this.props.match.params.skip != undefined) {
			this.props.makeInitialSearch(this.props.match.params.skip);
		}
		setTimeout(() => {
			infinityScrollTimeout = false;
		}, 3000);
	}

	toggleFacetDisplay() {
		this.props.toggleFacetDisplay();
        this.props.facettoggle ? document.body.classList.add('is-blocked') : document.body.classList.remove('is-blocked');
	}

	//Infinityscroll
	infinityscrollLoad() {
		let skip = this.props.match.params.skip != undefined ? this.props.match.params.skip : 0;
		skip = parseInt(skip);

		if (skip + this.props.list.length < this.props.resultcount && !infinityScrollTimeout) {
			this.props.loadMoreResults(this.props.search, skip + this.props.list.length);
		}
	}

	//Render
	renderCrawlerLink() {
		let skip = this.props.match.params.skip;
		if (skip == undefined) {
			skip = 0;
		}
		if (this.props.resultcount == undefined || parseInt(skip) + 20 >= parseInt(this.props.resultcount)) {
			return "";
		}

		return (
			<a className="crawlerskiplink" href={"/produkt/" + (parseInt(skip) + 20)}>
				Flere produkter
			</a>
		);
	}

	render() {
		return (
			<div className="SearchContainer">
				<div className="row">
					<SearchComponent
						backgroundColor="#660a5e"
						animated={true}
						text={this.props.search === "*" ? "" : this.props.search}
						page="ProductSearch"
					/>
				</div>
				<div className="row">
					{(typeof this.props.facets !== "undefined" && this.props.resultcount > 0) ? (
						<div className="row-wrap-fix">
                            {!this.props.facettoggle && (<div className="ProductSearchFilter__background" onClick={() => {this.toggleFacetDisplay()}} />)}
                            <ProductSearchFilter
                                facets={this.props.facets}
                                toggle={this.props.facettoggle}
                                handleToggle={this.toggleFacetDisplay} />
						</div>
					) : (
						<div className="ProductSearchFilter col-2" />
					)}
					{!this.props.isLoading ?
                        <div className="col">
                            <div className="ProductSearch container-fluid">
                                <div className="ProductSearch__result-heading col">
                                    <div className="row">
                                        {this.props.search === "*" || !this.props.search
                                            ? "Alle produkter"
                                            : `Søkeresultat for "${this.props.search}"`}
                                    </div>
                                </div>
                                <div className="ProductSearch__result-count col">
                                    <div className="row">
                                        {this.props.list.length > 0 ? <p>Viser {this.props.list.length} av {this.props.resultcount || 0} resultater.</p> : <p>Ingen treff</p>}
                                    </div>
                                </div>
                                <div className="ProductSearch__filter-button col">
                                    <div className="row">
                                        <Button
                                            text="Filtrer produkter"
                                            rounded={true}
                                            fullwidth={true}
                                            caps={true}
                                            onClick={this.toggleFacetDisplay}
                                        />
                                    </div>
                                </div>
                                <ProductList
                                    list={this.props.list}
                                    onInfinityScroll={this.infinityscrollLoad}
                                    className={this.props.isLoading ? "disabled" : ""}
                                />
                                {this.renderCrawlerLink()}
                            </div>
                        </div> :
                        <PageLoadSpinner />
					}

				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		list: state.SearchReducer.list,
		facets: state.SearchReducer.facets,
		resultcount: state.SearchReducer.number,
		isLoading: state.SearchReducer.isLoading,
		searchDone: state.SearchReducer.searchDone,
		search: state.SearchReducer.query,
		facettoggle: state.SearchFilterReducer.toggleDisplay
	};
}

function mapDispatchToProps(dispatch) {
	return {
		loadMoreResults: (search, skip) => {
			dispatch(infinityScroll(search, skip));
		},
		makeInitialSearch: skip => {
			dispatch(searchAction("*", skip));
		},
		toggleFacetDisplay: () => {
			dispatch(toggleDisplayFacets());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
