import React from 'react';

export default class SearchSuggestionList extends React.Component {
    render() {
        if (this.props.loaded) {
            return (
                <div id="searchResultsLive">
                    {
                        this.props.list.length > 0 ?
                            this.props.list.map((entry, index) => {
                                return (
                                    <div className="suggestedSearch" key={"suggestion_" + index} onMouseDown={this.props.search}><a>{entry.fellesProduktnavn}</a></div>
                                );
                            }) : <div>Fant ikke søkeforslag</div>

                    }

                </div>
            );
        }
        else return "";
    }
}
