import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './Header.scss';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuToggle: true
		};

		this.toggleMenu = this.toggleMenu.bind(this);
	}

    componentWillReceiveProps() {
		this.toggleMenu(true);
	}

	render() {
		return (
			<header className="Header container-fluid">
				<div className="row">
					<div className="Header__mobile-menu col-1" onClick={() => this.toggleMenu()}>
						<i className={this.state.menuToggle ? 'fa fa-bars' : 'fa fa-times'} />
					</div>
					<Link to="/" className="Logo col-2">
						VetDuAt.no
					</Link>
					<Navigation toggle={this.state.menuToggle} toggleAction={this.toggleMenu} />
				</div>
			</header>
		);
	}

	toggleMenu(force = false) {
		force ? this.setState({ menuToggle: true }) : this.setState({ menuToggle: !this.state.menuToggle });
		this.state.menuToggle && !force ? document.body.classList.add('is-blocked') : document.body.classList.remove('is-blocked');
	}
}

Header.propTypes = {};
Header.defaultProps = {};

function mapStateToProps(state) {
	return {
		router: state.router
	};
}
export default withRouter(connect(mapStateToProps, null)(Header));
