import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SEOLink extends Component {
	constructor(props) {
		super(props);

		this.removeSpecialCharacters = this.removeSpecialCharacters.bind(this);
	}

	removeSpecialCharacters(str) {
		return encodeURI(
			str
				.toLowerCase()
				.replace(/ /g, '-')
				.replace(/[`~!#$^*()_|+=?¿;:'".<>\{\}\[\]\\\/]/gi, '')
				.replace(/%/g, 'pct')
				.replace(/,/g, '.')
				.replace(/[æä]/g, 'ae')
				.replace(/[åãâàá]/g, 'a')
				.replace(/[øöôõóò]/g, 'o')
				.replace(/[éèêë]/g, 'e')
				.replace(/ñ/g, 'n')
				.replace(/í/g, 'i')
				.replace(/[úü]/g, 'u')
				.replace(/@/g, '-at-')
				.replace(/&/g, '-og-')
				.replace(/—|-+/g, '-')
		);
	}

	render() {
		let returnUrl = `${this.props.base}${this.props.linkid}`;
		if (this.props.firma) returnUrl += `/${this.removeSpecialCharacters(this.props.firma)}`;
		if (this.props.produktnavn) returnUrl += `/${this.removeSpecialCharacters(this.props.produktnavn)}`;
		return (
			<Link to={returnUrl} {...this.props}>
				{this.props.children}
			</Link>
		);
	}
}

export default SEOLink;
