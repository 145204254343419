import React from 'react';
import { connect } from 'react-redux';
import { getRecentBlogPosts } from '../../redux/blog';
import { Link } from 'react-router-dom';

import Article from '../Utility/Article/Article';

class RecentBlogPostsComponent extends React.Component {
	componentWillMount() {
		if (this.props.posts.length == 0) {
			this.props.getPosts();
		}
	}

	render() {
		return (
			<div className="Home__articles Home__articles--is-blogposts">
				{/*{this.props.posts.map((post, index) => {*/}
				{/*return (*/}
				{/*<Article backgroundImage={post.featuredMedia} title={post.title} path={"/blog/" + post.id} key={"recent-blog-" + index}/>*/}
				{/*);*/}
				{/*})}*/}
				<Article
					backgroundColor="#660a5e"
					title="VetDuAt du her kan søke i nær 4.000 økologiske produkter, og nær 40 forskjellige merkeordninger?"
				/>
				<Article
					backgroundImage={`/images/wp-hero/2.jpg`}
					title="Leter du etter en vareleverandør? Søk blant over 1350 vareleverandører!"
				/>
				<Article
					backgroundImage={`/images/wp-hero/3.jpg`}
					title="Norges største oversikt over dine dagligvarer!"
				/>
				<Article backgroundColor="#e20076" title="VetDuAt man også kan søke blant nonfood-produkter?" />
				<Article
					preTitle="Er du vareleverandør?"
					title="Ønsker du å oppdatere innholdet om egne produkter og selskap?"
					buttonText="Logg inn i EPD"
					buttonCaps={true}
					buttonLink="https://epd.tradesolution.no/"
				/>
				<Article
					preTitle="Er du vareleverandør?"
					title="Mangler dere produktbilder?"
					buttonText="Fotostudio"
					buttonCaps={true}
					buttonLink="https://tradesolution.no/tjenester/fotostudio/"
				/>
				<Article backgroundColor="#ffffff" title="" />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		posts: state.BlogReducer.recent_list
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getPosts: () => {
			dispatch(getRecentBlogPosts(7));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentBlogPostsComponent);
