import React from 'react';

export default class PageLoadSpinner extends React.Component {
    render() {
        let isIE = false;

        if (typeof (document) !== 'undefined') {
            isIE = /*@cc_on!@*/false || !!document.documentMode;
        }

        if (isIE) {
            return <div></div>;
        }
        return (
            <div className="Spinner">
                <div className="Spinner__inner" />
            </div>
        );
    }
}