import dev from './env/dev';
import test from './env/test';
import local from './env/local';
import prod from './env/prod';

export function getConfig() {
    const { host } = window.location;
    if (host.indexOf('localhost') > -1) {
        return local;
    }
    if (host.indexOf('dev') > -1) {
        return dev;
    }
    if (host.indexOf('test') > -1) {
        return test;
    }
    return prod;
}